import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams,
  useHistory,
  Redirect
} from "react-router-dom";

import './App.css';
import Thumbs from './components/Thumbs';

import btVoltar from './assets/images/voltar.png';
import btAvancar from './assets/images/avancar.png';

export default function App() {

  useEffect(() => {
    document.title = "Games Editora LT";
 }, []);  


 const objSeries = [
  {
    serie: '1º ano',
    background: 'images/backgrounds/bg1.png',
    recursos: [{id:1,nome:'Por onde eu ando', link:'https://gameseditoralt.com.br/transito1/PorOndeEuAndo/', thumb: '1Ano/1-Ano-01-Por-Onde-Eu-Ando.png'},
               {id:2,nome:'Meios de transporte', link:'https://gameseditoralt.com.br/transito1/MeiosDeTransporte/', thumb: '1Ano/1-Ano-02-Meios-De-Transporte.png'},
               {id:3,nome:'Ver e enxergar', link:'https://gameseditoralt.com.br/transito1/VerEEnxergar/', thumb: '1Ano/1-Ano-03-Ver-E-Enxergar.png'},
               {id:4,nome:'Atenção aos sons', link:'https://gameseditoralt.com.br/transito1/AtencaoAosSons/', thumb: '1Ano/1-Ano-04-Atencao-Aos-Sons.png'}
              ]
  },
  {
    serie: '2º ano',
    background: 'images/backgrounds/bg2.png',
    recursos: [{id:1, nome:'Aqui é seguro', link:'https://gameseditoralt.com.br/transito2/AquiESeguro/', thumb: '2Ano/2-Ano-01-Aqui-E-Seguro.png'},
               {id:2, nome:'Para onde eu vou?', link:'https://gameseditoralt.com.br/transito2/ParaOndeEuVou/', thumb: '2Ano/2-Ano-02-Para-Onde-Eu-Vou.png'},
               {id:3, nome:'Localizando-se nos ambientes', link:'https://gameseditoralt.com.br/transito2/LocalizandoseNosAmbientes/', thumb: '2Ano/2-Ano-03-LocalizandoSe-Nos-Ambientes.png'},
               {id:4, nome:'Sem pressa', link:'https://gameseditoralt.com.br/transito2/SemPressa/', thumb: '2Ano/2-Ano-04-Sem-Pressa.png'},
              ]
  },
  {
    serie: '3º ano',
    background: 'images/backgrounds/bg3.png',
    recursos: [{id:1, nome:'Para que servem as ruas', link:'https://gameseditoralt.com.br/transito3/ParaQueServemAsRuas/', thumb: '3Ano/img1.png'},
               {id:2, nome:'Evolução dos meios de transporte terrestre', link:'https://gameseditoralt.com.br/transito3/EvolucaoDosMeiosDeTransporteTerrestre/', thumb: '3Ano/img2.png'},
               {id:3, nome:'Organização dos espaços de vivência', link:'https://gameseditoralt.com.br/transito3/OrganizacaoDosEspacosDeVivencia/', thumb: '3Ano/img3.png'},
               {id:4, nome:'Espaço da brincadeira', link:'https://gameseditoralt.com.br/transito3/EspacoDaBrincadeira/', thumb: '3Ano/img4.png'},
              ]
  },
  {
    serie: '4º ano',
    background: 'images/backgrounds/bg4.png',
    recursos: [{id:1, nome:'Componentes do espaço rural', link:'https://gameseditoralt.com.br/transito4/ComponentesDoEspacoRural/', thumb: '4Ano/img3.png'},
               {id:2, nome:'Componentes do espaço urbano', link:'https://gameseditoralt.com.br/transito4/ComponentesDoEspacoUrbano/', thumb: '4Ano/img4.png'},
               {id:3, nome:'Atenção pedestre', link:'https://gameseditoralt.com.br/transito4/AtencaoPedestre/', thumb: '4Ano/img2.png'},
               {id:4, nome:'Atenção motorista', link:'https://gameseditoralt.com.br/transito4/AtencaoMotorista/', thumb: '4Ano/img1.png'},
              ]
  },
  {
    serie: '5º ano',
    background: 'images/backgrounds/bg5.png',
    recursos: [{id:1, nome:'A cidade e os cidadãos', link:'https://gameseditoralt.com.br/transito5/ACidadeEOsCidadaos/', thumb: '5Ano/img1.png'},
               {id:2, nome:'Cidadania, estradas e rodovias', link:'https://gameseditoralt.com.br/transito5/CidadaniaEstradasERodovias/', thumb: '5Ano/img2.png'},
               {id:3, nome:'Deslocamento e transporte', link:'https://gameseditoralt.com.br/transito5/DeslocamentoETransporte/', thumb: '5Ano/img3.png'},
               {id:4, nome:'Tecnologia no trânsito', link:'https://gameseditoralt.com.br/transito5/TecnologiaNoTransito/', thumb: '5Ano/img4.png'},
              ]
  },
  {
    serie: '6º ano',
    background: 'images/backgrounds/bg6.png',
    recursos: [{id:1, nome:'A origem das cidades', link:'https://gameseditoralt.com.br/transito6/AOrigemDasCidades/', thumb: '6Ano/img1.png'},
               {id:2, nome:'Minha cidade', link:'https://gameseditoralt.com.br/transito6/MinhaCidade/', thumb: '6Ano/img2.png'},
               {id:3, nome:'Conhecendo meu bairro', link:'https://gameseditoralt.com.br/transito6/ConhecendoMeuBairro/', thumb: '6Ano/img3.png'},
               {id:4, nome:'A rua: Espaço coletivo', link:'https://gameseditoralt.com.br/transito6/ARuaEspacoColetivo/', thumb: '6Ano/img4.png'},
               {id:5, nome:'Trânsito: Uma forma de se deslocar', link:'https://gameseditoralt.com.br/transito6/TransitoUmaFormaDeSeDeslocar/', thumb: '6Ano/img5.png'},
               {id:6, nome:'O trânsito de pedestres', link:'https://gameseditoralt.com.br/transito6/OTransitoDePedestres/', thumb: '6Ano/img6.png'},
               {id:7, nome:'Meio ambiente e segurança no trânsito', link:'https://gameseditoralt.com.br/transito6/MeioAmbienteESegurancaNoTransito/', thumb: '6Ano/img7.png'},
              ]
  },
  {
    serie: '7º ano',
    background: 'images/backgrounds/bg7.png',
    recursos: [{id:1, nome:'História do trânsito', link:'https://gameseditoralt.com.br/transito7/HistoriaDoTransito/', thumb: '7Ano/img1.png'},
               {id:2, nome:'Diferentes tipos de transportes nas cidades', link:'https://gameseditoralt.com.br/transito7/DiferentesTiposDeTransporteNasCidades/', thumb: '7Ano/img2.png'},
               {id:3, nome:'Trânsito e suas regras', link:'https://gameseditoralt.com.br/transito7/OTransitoESuasRegras/', thumb: '7Ano/img3.png'},
               {id:4, nome:'Trânsito e cidadania', link:'https://gameseditoralt.com.br/transito7/TransitoECidadania/', thumb: '7Ano/img4.png'},
               {id:5, nome:'Não combina com o trânsito', link:'https://gameseditoralt.com.br/transito7/NaoCombinaComTransito/', thumb: '7Ano/img5.png'},
               {id:6, nome:'Inclusão e diversidade', link:'https://gameseditoralt.com.br/transito7/InclusaoEDiversidade/', thumb: '7Ano/img6.png'},]
  },
  {
    serie: '8º ano',
    background: 'images/backgrounds/bg8.png',
    recursos: [{id:1, nome:'Trânsito e espaço social', link:'https://gameseditoralt.com.br/transito8/TransitoEEspacoSocial/', thumb: '8Ano/img1.png'},
               {id:2, nome:'A linguagem da comunicação no trânsito', link:'https://gameseditoralt.com.br/transito8/ALinguagemDaComunicacaoNoTransito/', thumb: '8Ano/img2.png'},
               {id:3, nome:'Comunicação e interação no trânsito', link:'https://gameseditoralt.com.br/transito8/ComunicacaoEInteracaoNoTransito/', thumb: '8Ano/img3.png'},
               {id:4, nome:'Medidas de moderação de tráfego', link:'https://gameseditoralt.com.br/transito8/MedidasDeModeracaoDeTrafego/', thumb: '8Ano/img4.png'},
              ]
  },
  {
    serie: '9º ano',
    background: 'images/backgrounds/bg9.png',
    recursos: [{id:1, nome:'Segurança e prevenção de acidentes', link:'https://gameseditoralt.com.br/transito9/SegurancaEPrevencaoDeAcidentes/', thumb: '9Ano/img1.png'},
               {id:2, nome:'Mobilidade sustentável', link:'https://gameseditoralt.com.br/transito9/MobilidadeSustentavel/', thumb: '9Ano/img2.png'},
               {id:3, nome:'Cidadania e qualidade de vida', link:'https://gameseditoralt.com.br/transito9/CidadaniaEQualidadeDeVida/', thumb: '9Ano/img3.png'},
               {id:4, nome:'Tecnologias e profissionais do trânsito', link:'https://gameseditoralt.com.br/transito9/TecnologiasEProfissionaisDoTransito/', thumb: '9Ano/img4.png'},
               {id:5, nome:'Trânsito e tecnologia', link:'https://gameseditoralt.com.br/transito9/TransitoETecnologia/', thumb: '9Ano/img5.png'},
               ]
  },

];  

function Menu() 
{
  let { id } = useParams();

  let valor = id.substr(0,8);
  
  var indice = id.substr(8);

  var history = useHistory();

  function voltarSerie(e)
  {
    indice--;

    if(indice < 1)
      indice = 9
    
    history.push(`/transito${indice}`);
  }

  function avancarSerie(e)
  {
    indice++;
    
    if(indice > 9)
      indice = 1;

    history.push(`/transito${indice}`);
  }

  if(valor != 'transito')
  {
    return(
      <Redirect to='/transito1' />
    );
  }
  else if(indice < 1 || indice > 9)
  {
    return(
      <Redirect to='/transito1' />
    );
  }
  else
  {
    //window.location.href = "/transito1";
    return (
      <div className="App">

        <div style={{
          backgroundImage: `url(${require("./assets/images/backgrounds/bg" + (indice) + ".jpg")?.default})`,
          alignItems:'center',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          flex: 1,
        }}>

          <div className="logo">
            <img src={require(`./assets/images/logo-livros.png`)?.default} alt="Logo"/>
          </div>

          <div className="serie">
          
          <a className="links" onClick={voltarSerie}> 
            <img src={btVoltar}/> </a>
          
            <img src={require(`./assets/images/anos/livro${indice}.png`)?.default} alt={`${indice}º Ano`} style={{margin:10}}/> 
          
          <a className="links" onClick={avancarSerie}> 
            <img src={btAvancar}/> </a>
          
          </div>

          <div className="center">
            <div className="containerThumbs">
              <Thumbs recursos={objSeries[indice - 1].recursos}/>
            </div>
          </div>

          <div className="logoLT">
            <img src={require(`./assets/images/logo-lt.png`)?.default} alt="Logo LT"/>
          </div>

        </div>
        
      </div>
    );
  }
}
  return (
    <Router>
        <Switch>
          <Route path="/:id" children={<Menu />} />
          <Redirect to="/transito1" />
        </Switch>
    </Router>
  );
}

function NotFound()
{
  return(
    <div>
      <h2>Página não encontrada</h2>
    </div>
  );
}