import '../../App.css';

function Thumbs(props)
{

    const recursos = props.recursos;

    const listItens = recursos.map((item) =>

        //<p>{`../../assets/images/thumbs/${item.thumb}`}</p>

        <a href={item.link} target="_blank" key={item.id}> <img className="thumbs" src={require(`../../assets/images/thumbs/${item.thumb}`)?.default}/> </a>
    );

    return(
        <div className="thumbs">
            {listItens}
        </div>
    );
}

export default Thumbs;